.App {
  text-align: center;
}

input.search::-webkit-input-placeholder {
  font-size: 0.7em;
  line-height: 30px;
}
.form-container {
  width: 100%;
  padding: 15px 15px;
  flex: 1;
  overflow: scroll;
}
.form-container span {
  padding-left: 15px;
  margin-bottom: 5px;
}
.form-input {
  padding: 0px 10px;
  display: flex;
  width: 98%;
  justify-content: flex-start;
  background: transparent;
  transition: 150ms;
  border-radius: 25px;
  background: #fff;
  border: 2px solid #eee;
  transition-delay: 100ms;
  margin: auto auto 5px auto;
}
.form-input:focus-within {
  transition-delay: 0s;
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.2);
}
.form-input input {
  line-height: 28px;
  border-radius: 5px;
  outline: none;
  font-weight: bolder;
  border: none;
  width: 100%;
}
.form-input input::-webkit-input-placeholder {
  opacity: 0.4;
  font-size: 0.8em;
  text-transform: uppercase;
}
.testing {
  position: relative;
  height: 100%;
  margin-bottom: 25px;
  border: 2px solid rgba(255, 0, 0, 0.3);
}
.testing:after {
  z-index: 9999;
  display: block;
  top: 115%;
  left: 10px;
  position: absolute;
  font-size: 8px;
  text-transform: uppercase;
  color: red;
  font-weight: bold;
  content: attr(errormsg);
}
.form-container .half {
  display: inline-block;
  width: 50%;
  padding-right: 5px;
}
div.search {
  padding: 5px 20px 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.search > div {
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  transition: 150ms;
  border-radius: 25px;
  background: #fff;
  border: 2px solid #eee;
  transition-delay: 100ms;
}
div.search:focus-within > div {
  transition-delay: 0s;
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.2);
}
.addressbookCard {
  opacity: 0.85;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0px 9px 13px -10px rgba(0, 0, 0, 0.1);
  transition: 250ms;
}
.addressbookCard:hover {
  opacity: 1;
  cursor: pointer;
  border: 1px solid var(--brand-cyan);
  box-shadow: 0px 13px 13px -10px rgba(0, 0, 0, 0.1);
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modalClose {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  /* background:#fff; */
  /* border-radius:50%; */
}

.notify-wrapper {
  /* max-width: 1200px; */
  /* border-radius: 20px; */
  font-weight: 700;
}

.usps {
  display: inline-block;
  margin-left: 60px;
  position: relative;
}
.usps:before {
  content: "";
  background: url("https://images.swiftpost.com/logo-USPS.png");
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -60px;
  width: 55px;
  height: 10px;
}
.notification-block {
  background: rgba(255, 255, 255, 0.85);
  padding: 10px 20px;
  margin: 10px;
  border-radius: 8px;
  display: inline-block;
}

@media (max-width: 992px) {
  .notification-block {
    width: 100%;
  }
}

@keyframes snowY {
  0% {
    background-position-y: 0, bottom, 0;
  }
  100% {
    background-position-y: 210px, bottom, 0;
  }
}
@keyframes snowX {
  0% {
    background-position-x: 0, right, 0;
  }
  100% {
    background-position-x: 3120px, right, 0;
  }
}

.resultRow strong {
  color: var(--brand-cyan);
  font-family: "Matter SemiBold";
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4BB543;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4BB543;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



body {
  margin: 0;
}

.Login {
  padding: 60px 0;
  /* height:100%; */
  min-height: 100vh;
  box-sizing: border-box;
  background: var(--navy);
}

@media only screen and (max-width: 600px) {
  .Login {
    padding: 20px;
  }
}

.Login img {
  max-width: 250px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.Login div.Paper {
  margin: 0 auto;
  max-width: 320px;
}

.Login div.Paper div.Input.no-margin {
  margin: 0;
}

.Login div.Paper div.Input,
.Login h3 {
  margin-bottom: 25px;
}

.Login .callout {
  color: #ddd;
  margin: 30px 0 10px 0;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
}

.Login .callout.text-left {
  text-align: left;
  margin: 0;
}

.Login .callout.text-left div {
  padding: 5px 0 0 0;
}

.Login .callout.text-left a {
  color: #ccc;
}

.Login button.loginSubmit {
  margin: 20px 0 20px 0;
}

.Login .white {
  color: "#fff";
}