a {
  text-decoration: none;
}

.Home h1.noButton {
  padding-bottom: 0px;
  margin-bottom: 0;
}

.navicon {
  margin: 0 8px;
}
@media only screen and (max-width: 600px) {
  .navicon {
    margin: 0 0px;
  }
}

.Home {
  padding-top: 80px;
  margin-bottom: 150px;
  /* text-align: center; */
}

.Home h1 {
  position: relative;
  line-height: 80px;
  margin-top: 0;
  font-weight: 300;
  /* background:#ff0000; */
  /* background:#f3f3f3; */
  padding-left: 24px;
  margin: -20px -24px 0px -24px;
  font-size: 30px;
  color: var(--navy);
}

.Home h1 > button {
  margin-left: 25px;
  position: absolute;
  /* right:24px; */
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {
  .Home h1 {
    margin-top: -45px;
    padding-top: 15px;
    font-size: 1.3em;
    line-height: 1.5em;
  }
  .Home h1 > button {
    margin: 10px auto 10px -12px;
    width: 100%;
    position: relative;
    /* right:24px; */
    top: auto;
    transform: none;
  }

  .Home h5 {
    font-size: 1.2em;
    margin: 0;
  }
  .test {
    margin-left: -55px;
  }
  .noFlex {
    flex: none;
  }
  .Home h1.noButton {
    padding-bottom: 15px;
    margin-bottom: 0;
  }
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes h4 {
  /* font-family: "Open Sans", sans-serif; */
  /* font-weight: 600; */
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.Home .notes p {
  color: #666;
}

footer {
  padding: 75px 75px 75px 75px;
  background: #242b46;
}

footer a {
  color: #eee;
}

footer a:hover {
  text-decoration: underline;
}

footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

footer li {
  margin-bottom: 10px;
}

.stepper {
  margin: -16px -24px 15px -24px !important;
  width: 110%;
  background: var(--brand-cyan);
  padding: 15px 20px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .stepper {
    width: 100%;
    margin: -16px -15px 15px -16px !important;
  }
}

.active {
  background: red;
}

.pac-container { z-index: 9999 };