#root,
#app,
#map {
  height: 100%;
}

#state {
  padding: 0 20px 0 0;
  box-sizing: border-box;
  width: 350px;
  float: left;
}

#map-container {
  margin-left: "200px" !important;
  height: "400px" !important;
}

@media only screen and (max-width: 700px) {
  #state {
    float: none;
    width: 100%;
    padding: 0;
  }
  #map-container {
    margin-left: "0px" !important;
    height: "400px" !important;
  }
}
