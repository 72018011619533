:root {
  --brand-cyan: #00addb;
  --brand-cyan-25: #bfeaf6;
  --navy: #051e51;
  --white: #ffffff;
  --deep-blue: #004b9f;
  --beige: #f6f5ed;
  --beige-50: #fafaf6;
  --yellow: #fcf268;
  --pop-teal: #a1ffdc;
  --cream: #edeac0;
  --orange: #ff7f43;
}

@font-face {
  font-family: "Matter Regular";
  font-style: normal;
  font-weight: 430;
  font-display: block;
  src: local("Matter-Regular"),
    url("./fonts/Matter-Regular.woff2") format("woff2"),
    url("./fonts/Matter-Regular.woff") format("woff"),
    url("./fonts/Matter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Matter SemiBold";
  font-style: normal;
  font-weight: 670;
  font-display: block;
  src: local("Matter-SemiBold"),
    url("./fonts/Matter-SemiBold.woff2") format("woff2"),
    url("./fonts/Matter-SemiBold.woff") format("woff"),
    url("./fonts/Matter-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Matter Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
}

.Login {
  padding: 60px 0;
  /* height:100%; */
  min-height: 100vh;
  box-sizing: border-box;
  background: var(--navy);
}

@media only screen and (max-width: 600px) {
  .Login {
    padding: 20px;
  }
}

.Login img {
  max-width: 250px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.Login div.Paper {
  margin: 0 auto;
  max-width: 320px;
}

.Login div.Paper div.Input.no-margin {
  margin: 0;
}

.Login div.Paper div.Input,
.Login h3 {
  margin-bottom: 25px;
}

.Login .callout {
  color: #ddd;
  margin: 30px 0 10px 0;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
}

.Login .callout.text-left {
  text-align: left;
  margin: 0;
}

.Login .callout.text-left div {
  padding: 5px 0 0 0;
}

.Login .callout.text-left a {
  color: #ccc;
}

.Login button.loginSubmit {
  margin: 20px 0 20px 0;
}

.Login .white {
  color: "#fff";
}

