.Loading {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

.Loading .loadingIcon {
  position: absolute;
  top: calc(45% - 40px);
  left: calc(50% - 40px);
  height: 80px;
  width: 80px;
}

.Loading .loadingText {
  position: absolute;
  top: calc(45% + 70px);
  left: 50%;
  margin: 0;
  height: 100px;
  width: 300px;
  color: #fff;
  font-weight: normal;
  text-align: center;
  transform: translateX(-50%);
}
